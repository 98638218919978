
import ClubService from '@/services/Enitities/ClubService'
import moment from 'moment'
import GroupService from '@/services/Enitities/GroupService'
import { GroupTypes } from '@/models/Enum'
import UserService from '@/services/Enitities/UserService'
import Application from '@/services/App/Application'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import { ClubMember } from '@/models/ClubMember'
import { onTimeDialog, onTimeDialogs } from '@/services/App/onTimeDialog'

export default {
  name: 'ClubMemberRegistry',
  props: {
    user: {
      default: Object
    }
  },
  data ():unknown {
    return {
      onTimeDialog,
      onTimeDialogs,
      GroupTypes,
      moment,
      club: ClubService.get(),
      groups: [],
      stepper: 1,
      type: '',
      rules: {
        firstname: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        lastname: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        email: [
          (v):boolean|string => (/.+@.+\..+/.test(v) || v?.length === 0) || v?.length === 0 || this.$t('emailValid')
        ],
        birthdate: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        birthdateChild: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => moment().diff(v, 'years', true) >= 18 || 'Your child is an adult, You are not allowed to share their information'
        ],
        password: [
          (v):boolean|string => (v?.length >= 8 || v?.length === 0) || this.$t('passwordLength')
        ],
        consent: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        type: [
          ():boolean|string => !!this.type || this.$t('requiredField')
        ],
        groups: [
          ():boolean|string => !!this.member.groups.length || this.$t('requiredField')
        ]
      },
      member: {
        firstname: '',
        lastname: '',
        birthdate: '',
        password_validate: '',
        password: '',
        groups: []
      },
      error: null,
      loading: false,
      userIsAClubMember: false
    }
  },
  async created (): Promise<void> {
    this.groups = await (new GroupService()).getAll()
    const members = LocalStorage.get(StorageNames.Members) as ClubMember[]
    this.userIsAClubMember = !members.find((member) => { return member?.user?.id === this.user?.id })
  },
  methods: {
    async registerMember ():Promise<void> {
      this.loading = true
      let response
      if (this.type === 'member') {
        response = await UserService.registerClubMember(this.member)
      }
      if (this.type === 'child') {
        response = await UserService.registerChild(this.member)
      }
      const data = await response?.json()
      Application.setLocalData(data)
      this.stepper = 4
      this.loading = false
    }
  }
}
