import { Api } from '@/services/API/Api'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import { Club } from '@/models/Club'

export class ClubService {
  api:Api
  constructor () {
    this.api = new Api()
  }

  public static get (): Club {
    return LocalStorage.get(StorageNames.Club) as Club
  }

  public async getClubEvents (start:string, end:string):Promise<Response> {
    const club = ClubService.get()
    return await this.api.request('club/' + club.slug + '/events', 'GET', {
      start: start,
      end: end
    })
  }

  public async getClubNews ():Promise<Response> {
    const club = ClubService.get()
    return await this.api.request('club/' + club.slug + '/news', 'GET')
  }

  public async Load ():Promise<Club> {
    const subdomain = window.location.hostname.split('.')[0]
    const clubFetch = await this.api.request('club/' + subdomain, 'GET')
    if (clubFetch.ok) {
      const response = await clubFetch.json()
      LocalStorage.set(StorageNames.Club, response)
      LocalStorage.set(StorageNames.Teams, response.groups)
    } else {
      location.replace('https://keeva.app')
    }
    return LocalStorage.get(StorageNames.Club) as Club
  }

  public async updateClub (club:Club): Promise<Club | false> {
    const response = await this.api.request('club/', 'POST', club)
    if (response.ok) {
      const club = await response.json() as Club
      LocalStorage.set(StorageNames.Club, club)
      return club
    }
    return false
  }
}

export default ClubService
